<template>
  <Auth
    v-if="!thisUser"
    :login-label="$t('login')"
    :signup-label="$t('signup')"
  />
  <div v-else>
    <AccountWelcome :name="thisUser.firstName" @logout="logoutUser" />
    <AccountOrders />
    <AccountAddresses />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { THIS_USER } from '@/store/gettersTypes';
import { SET_NEXT_ROUTE, SET_USER } from '@/store/mutationTypes';
import { LOGOUT } from '@/store/actionTypes';
import { Auth, AccountWelcome, AccountAddresses, AccountOrders } from '../components';

export default {
  components: {
    Auth,
    AccountWelcome,
    AccountAddresses,
    AccountOrders,
  },

  computed: {
    ...mapGetters([THIS_USER]),
  },

  mounted() {
    this.$nextTick(() => {
      this.setNextRoute('/account');
    });
  },

  methods: {
    ...mapMutations([SET_NEXT_ROUTE, SET_USER]),
    ...mapActions([LOGOUT]),
    logoutUser() {
      this.logout(() => {
        this.setUser(null);
        this.setNextRoute('/account');
      });
    },
  },
};
</script>
